import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import { persistStore, persistReducer, REGISTER, PURGE, PERSIST, PAUSE, REHYDRATE, FLUSH } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import filterSlice from './slices/filterSlice';
const persistConfig = {
   key: 'root_v2',
   storage,
   blacklist: []
};

const rootReducer = combineReducers({
   filters: filterSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
   reducer: persistedReducer,
   middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});


const persistor = persistStore(store);
export const wrapper = createWrapper(store);
export { store, persistor };